require('./bootstrap');
// window.Vue = require('vue').default;
// import BootstrapVue from 'bootstrap-vue';
// import {
//     ValidationProvider,
//     ValidationObserver,
//     localize,
//     extend
// } from 'vee-validate';
// import * as rules from 'vee-validate/dist/rules';
// import ro from 'vee-validate/dist/locale/ro.json';
// for (const rule in rules) {
//     extend(rule, rules[rule]);
// }
// localize('ro', ro);
// Vue.use(BootstrapVue);
//
//
// Vue.component('ValidationProvider', ValidationProvider);
// Vue.component('ValidationObserver', ValidationObserver);
// Vue.component('contact', require('./components/Contact.vue'));
//
//
// let app = new Vue({
//     el: '#app',
// });
//
//
//
